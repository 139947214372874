const urlWithRequiredProtocol = /^(https?:\/\/)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/;

const urlValidator = (
  url,
  isRequired = true,
  text = 'Invalid url',
  protocolRequired = false,
) => {
  if (!url && isRequired) {
    return 'Required';
  }

  if (url && protocolRequired && !urlWithRequiredProtocol.test(url)) {
    return text;
  }

  return /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/.test(url)
    ? undefined
    : text;
};

export default urlValidator;
