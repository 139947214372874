import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';

import { TableTypes } from '@components/Table/constants';
import {
  getOptInRetentionCrmList,
  importOptInRetention,
} from '@services/requests/optInRetention';
import { OPT_IN_RETENTION } from '@services/requests/requestResources';

import ConfirmDialog from '@ui/components/common/ConfirmDialog/ConfirmDialog';
import Table from '@components/Table';
import FieldWrapper from '@common/form/FieldWrapper';
import AutocompleteFormInput from '@common/AutocompleteFormInput/AutocompleteFormInput';
import Loading from '@common/Loading/Loading';
import ReusableButton from '@common/Button/Button';
import StatisticModal from '@components/integrations/OptInMigration/components/StatisticModal';

import { Grid } from '@material-ui/core';
import StatusesAccessor from '@common/MuiVirtualizedTable/components/accessors/StatusesAccessor';
import { optInStatuses } from '@components/PsaContacts/constants';
import useStyles from './styles';

const OptInMigration = () => {
  const [processing, setProcessing] = useState(false);
  const [crmList, setCrmList] = useState([]);
  const [sourceCrmId, setSourceCrmId] = useState('');
  const [targetCrmId, setTargetCrmId] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [statisticData, setStatisticData] = useState();

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setProcessing(true);
    getOptInRetentionCrmList().then(res => {
      setProcessing(false);
      setCrmList(res);
    });
  }, []);

  const sourceCrmOptions = useMemo(
    () => crmList.filter(crm => crm.value !== targetCrmId),
    [crmList, targetCrmId],
  );
  const targetCrmOptions = useMemo(
    () => crmList.filter(crm => crm.value !== sourceCrmId),
    [crmList, sourceCrmId],
  );

  const handleImport = useCallback(() => {
    importOptInRetention({ sourceCrmId, targetCrmId })
      .then(res => {
        setOpenDialog(false);
        setStatisticData(res);
      })
      .catch(err => enqueueSnackbar(err.message, { variant: 'error' }));
  }, [enqueueSnackbar, sourceCrmId, targetCrmId]);

  const columns = useMemo(
    () => [
      {
        name: 'Contact Name',
        key: 'contactName',
        sortable: true,
        searchable: true,
        minWidth: 200,
      },
      {
        name: 'Contact Phone',
        key: 'contactPhone',
        sortable: true,
        searchable: true,
        type: TableTypes.phoneNumber,
        minWidth: 200,
      },
      {
        name: 'Email',
        key: 'email',
        sortable: true,
        searchable: true,
        minWidth: 200,
      },
      {
        name: 'Opt-in Status',
        key: 'allowMessaging',
        searchable: true,
        sortable: true,
        isCustomAccessor: true,
        type: TableTypes.dropdown,
        dropDownValues: [
          { value: 'true', label: 'Opted-in' },
          { value: 'false', label: 'Opted-Out' },
        ],
        filterByAutocomplete: 'allowMessaging',
        minWidth: 200,
        width: 200,
        accessor: row => (
          <Grid container justifyContent="center" width="100%">
            <StatusesAccessor
              item={row.allowMessaging ? 0 : 1}
              array={optInStatuses}
            />
          </Grid>
        ),
      },
    ],
    [],
  );

  if (processing) {
    return <Loading />;
  }

  return (
    <div className={classes.container}>
      <div className={classes.inputContainer}>
        <FieldWrapper
          classNameContainer={classes.input}
          label="Source PSA"
          labelSize={12}
          contentSize={12}
          fullWidth
          content={
            <AutocompleteFormInput
              id="sourceCrmId"
              name="sourceCrmId"
              disabled={processing}
              input={{
                value: sourceCrmId,
                onChange: val => {
                  setSourceCrmId(val);
                },
              }}
              items={sourceCrmOptions}
              size="small"
            />
          }
        />
        <FieldWrapper
          classNameContainer={classes.input}
          label="Target PSA"
          labelSize={12}
          contentSize={12}
          fullWidth
          content={
            <AutocompleteFormInput
              id="targetCrmId"
              name="targetCrmId"
              disabled={processing}
              input={{
                value: targetCrmId,
                onChange: val => {
                  setTargetCrmId(val);
                },
              }}
              size="small"
              items={targetCrmOptions}
            />
          }
        />
        <ReusableButton
          label="Import"
          onClick={() => {
            setOpenDialog(true);
          }}
          id="import"
          viewType="blue"
          disabled={!sourceCrmId || !targetCrmId}
        />
      </div>
      {sourceCrmId && (
        <Table
          columns={columns}
          resource={`${OPT_IN_RETENTION}/${sourceCrmId}/get-contacts`}
          defaultSort={{ fieldName: 'contactName', order: 'ASC' }}
        />
      )}
      {openDialog && (
        <ConfirmDialog
          open
          setOpen={setOpenDialog}
          onCancel={() => {
            setOpenDialog(false);
          }}
          onAccept={handleImport}
          content="Are you sure you want to proceed with the import?"
          title="Confirm action"
          loading={processing}
        />
      )}
      {statisticData && (
        <StatisticModal
          statisticData={statisticData}
          setStatisticData={setStatisticData}
        />
      )}
    </div>
  );
};

export default OptInMigration;
