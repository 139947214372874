import React from 'react';
import crmSources from '@constants/crmSources';
import { Grid } from '@material-ui/core';

import HaloContacts from '@components/PsaContacts/components/integrations/halo/HaloContacts';
import AutotaskContacts from '@components/PsaContacts/components/integrations/autotask/AutotaskContacts';
import ZendeskContacts from '@components/PsaContacts/components/integrations/zendesk/ZendeskContacts';
import SuperOpsContacts from '@components/PsaContacts/components/integrations/superOps/SuperOpsContacts';
import Microsoft365Contacts from '@components/PsaContacts/components/integrations/microsoft365/Microsoft365Contacts';
import StatusesAccessor from '@common/MuiVirtualizedTable/components/accessors/StatusesAccessor';

import { TableTypes } from '@components/Table/constants';
import { optInStatuses } from '@components/PsaContacts/constants';

export default {};

export const getTable = (psaName, psaId, resource, syncDataIntoDatabase) => {
  switch (psaName) {
    case crmSources.Autotask.name:
      return (
        <AutotaskContacts
          psaId={psaId}
          psaName={psaName}
          resource={resource}
          syncDataIntoDatabase={syncDataIntoDatabase}
        />
      );
    case crmSources.Microsoft.name:
      return (
        <Microsoft365Contacts
          psaId={psaId}
          psaName={psaName}
          resource={resource}
          syncDataIntoDatabase={syncDataIntoDatabase}
        />
      );
    case crmSources.Halo.name:
      return (
        <HaloContacts
          psaId={psaId}
          resource={resource}
          psaName={psaName}
          syncDataIntoDatabase={syncDataIntoDatabase}
        />
      );
    case crmSources.Zendesk.name:
      return (
        <ZendeskContacts
          psaId={psaId}
          psaName={psaName}
          resource={resource}
          syncDataIntoDatabase={syncDataIntoDatabase}
        />
      );
    case crmSources.SuperOps.name:
      return (
        <SuperOpsContacts
          psaId={psaId}
          psaName={psaName}
          resource={resource}
          syncDataIntoDatabase={syncDataIntoDatabase}
        />
      );
    default:
      return <></>;
  }
};

export const optInColumn = syncDataIntoDatabase => {
  const baseColumn = {
    name: 'Opt-in Status',
    key: 'optedStatus',
    width: 140,
    minWidth: 140,
    tooltipTitle:
      'Filtering is available only when contact storage is enabled.',
    isCustomAccessor: true,
    filterByAutocomplete: 'optedStatus',
    accessor: row => (
      <Grid container justifyContent="center" width="100%">
        <StatusesAccessor item={row.optedStatus} array={optInStatuses} />
      </Grid>
    ),
  };

  if (syncDataIntoDatabase) {
    return {
      ...baseColumn,
      type: TableTypes.dropdown,
      dropDownValues: optInStatuses,
      searchable: true,
      width: 200,
      minWidth: 200,
    };
  }

  return baseColumn;
};
