import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';

import FieldWrapper from '@common/form/FieldWrapper';
import PhoneField from '@common/PhoneField';
import RadioInput from '@common/Radio/RadioInput';
import { TypeLabelValue } from '@common/propTypes/common';

const HelpdeskIntegrationPhoneField = ({
  disabled,
  countryCodesOptions,
  initialIcon,
}) => {
  return (
    <>
      <FieldWrapper
        label="Allow Transferring the Call to a Live Agent"
        labelSize={12}
        contentSize={12}
        content={
          <Field
            name="isActive"
            id="isActive"
            component={RadioInput}
            choices={[
              {
                label: 'Enabled',
                value: 'true',
              },
              {
                label: 'Disabled',
                value: 'false',
              },
            ]}
          />
        }
      />
      <PhoneField
        label=""
        phoneName="callTransferPhoneNumber"
        phoneCodeName="callTransferPhoneNumberCountryCode"
        countryCodesOptions={countryCodesOptions}
        initialIcon={initialIcon}
        disabled={disabled}
        showLabel={false}
      />
    </>
  );
};

HelpdeskIntegrationPhoneField.propTypes = {
  disabled: PropTypes.bool,
  initialIcon: PropTypes.node,
  countryCodesOptions: TypeLabelValue,
};

export default HelpdeskIntegrationPhoneField;
