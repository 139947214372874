import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    cursor: 'pointer',
    width: '100%',
    '& .MuiTypography-root': {
      fontWeight: 'bold',
      fontSize: '0.9375rem',
    },
  },
  margin: {
    marginRight: '5px',
  },
}));

export default useStyles;
