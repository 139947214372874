import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';

import Modal from '@ui/common/Modal/Modal';

import useStyles from '../styles';

const StatisticModal = ({ statisticData, setStatisticData }) => {
  const classes = useStyles();

  const content = useMemo(() => {
    const { failed, ...data } = statisticData;
    return (
      <div className={classes.statisticContainer}>
        <Typography className={classes.successText}>SUCCESS</Typography>
        <ul className={classes.statisticList}>
          {Object.keys(data).map(item => (
            <li key={item}>
              <strong>{item.charAt(0).toUpperCase() + item.slice(1)}</strong>
              <span>{` ${data[item]}`}</span>
            </li>
          ))}
        </ul>
        <ul className={classes.failedList}>
          {failed.map(item => (
            <li key={item.contactId}>
              <strong>{item.contactName}</strong>
              {`  - ${item.errorMessage}`}
            </li>
          ))}
        </ul>
      </div>
    );
  }, [classes, statisticData]);

  return (
    <Modal
      open
      setOpen={() => setStatisticData(null)}
      title="Statistic data"
      isCloseCross
    >
      {content}
    </Modal>
  );
};

StatisticModal.propTypes = {
  setStatisticData: PropTypes.func.isRequired,
  statisticData: PropTypes.shape({
    total: PropTypes.number.isRequired,
    retained: PropTypes.number.isRequired,
    skipped: PropTypes.number.isRequired,
    failed: PropTypes.arrayOf(
      PropTypes.shape({
        contactName: PropTypes.string.isRequired,
        errorMessage: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
};

export default StatisticModal;
