import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  toolBar: {
    display: 'flex',
    gap: '8px',
    padding: '16px',
    alignItems: 'flex-end',
  },
  buttonWrapper: {
    width: '120px',
  },
}));

export default useStyles;
