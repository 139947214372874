import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthenticated, useAuthProvider, useGetOne } from 'react-admin';
import _ from 'lodash';

import getData from '@services/api/common/getData';
import { defaultPalette, setPalette } from '@services/themes/mainTheme';
import { actions } from '@store/actions';
import appConfig from '@configs/appConfig';
import { getTenantPalettes } from '@components/Settings/CustomPalette/helpers';

import SimpleDialog from '@ui/components/common/Dialog/SimpleDialog';
import EmailLinkWrapper from '@common/EmailLinkWrapper/ActionFieldItem';

import { pricePlanMyPlan } from '@constants/appRoutes';
import resources from '@constants/resources';

import { TypeUserData } from '@common/propTypes/common';

import useStyles from './styles';

const getPricePlanInfo = () =>
  getData(`${appConfig.baseUrl}${pricePlanMyPlan}`);

const UserName = ({ userData }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const pricePlan = useSelector(state => state.pricePlan.plan || {});

  const authProvider = useAuthProvider();

  const id = authProvider.getUserId();

  useAuthenticated();

  const { data, loading, refetch } = useGetOne(resources.user, id);

  useEffect(() => {
    refetch();
  }, [refetch, id]);

  useEffect(() => {
    getPricePlanInfo()
      .then(d => dispatch(actions.setPricePlan(d)))
      .catch(e => console.error(e.message));
    getTenantPalettes()
      .then(d =>
        setPalette(
          _.isEmpty(JSON.parse(d.palette))
            ? defaultPalette
            : JSON.parse(d.palette),
        ),
      )
      .catch(e => console.error(e.message));
  }, [dispatch]);

  useEffect(() => {
    if (!Object.keys(pricePlan).length) return;

    if (pricePlan.errorMessage || pricePlan.isSubscriptionEnabled === false) {
      setOpen(true);
      return;
    }

    localStorage.setItem('tenantId', data?.tenantId ?? '');
    localStorage.setItem('roleId', data?.roleId ?? '');
    localStorage.setItem('profileImage', data?.imageUri ?? '');

    dispatch(actions.setCurrentUser(data));
  }, [data, dispatch, pricePlan]);

  if (loading) return null;

  const userName = userData?.username || data?.username;

  return (
    <div className={classes.container}>
      <span>{userName}</span>

      <SimpleDialog
        open={open}
        setOpen={setOpen}
        title="Subscription Issue Detected"
      >
        {pricePlan.errorMessage ? (
          <EmailLinkWrapper text={pricePlan.errorMessage} />
        ) : (
          <div>
            We’re having an issue retrieving the details of your billing plan;
            please contact our Support team at
            <a href="mailto:support@help.mspprocess.com">
              {' '}
              support@help.mspprocess.com
            </a>
          </div>
        )}
      </SimpleDialog>
    </div>
  );
};

UserName.propTypes = {
  userData: TypeUserData,
};

export default UserName;
