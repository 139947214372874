import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRedirect, useRefresh, useTranslate, useVersion } from 'react-admin';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { enqueueSnackbar } from 'notistack';
import { useLocation } from 'react-router-dom';

import { actions } from '@store/actions';
import { sortOrders } from '@constants/filters';
import { getPredefinedEvent } from '@constants/signalRMessageEvents';
import { socketMessageEvents as events } from '@constants/events';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import TestBox from '@common/TestBox/TestBox';
import DateTimeField from '@common/TableComponents/DateTimeField';
import eventBus from '@services/eventBus';

import UploadDialog from '@components/MailBox/components/Emails/UploadDialog';
import StatusCircle from '@common/StatusCircle/StatusCircle';
import TriggersList from '@components/MailBox/components/Emails/components/TriggersList';
import { TableTypes } from '@components/Table/constants';
import Table from '@components/Table';

import { letterStatusesOptions } from '@constants/letterStatuses';

import ListActions from '@common/ListActions/ListActions';
import WarningMessage from './WarningMessage';
import BulkActionButtons from './BulkActions/BulkActions';
import StatusField from './StatusField';
import {
  getLetterById,
  removeLetter,
  reParseMessage,
  testMessage,
} from '../../helpers';
import ActionField from './Actions/ActionField';
import SubjectField from '../Common/SubjectField';

import useStyles from './styles';

const ignoredStatus = 'Ignored';

const LettersList = ({ resource, mailBoxId }) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const translate = useTranslate();
  const redirect = useRedirect();
  const version = useVersion();
  const dispatch = useDispatch();
  const location = useLocation();

  const [defaultFilters, setDefaultFilters] = useState({});

  useMemo(() => {
    const dataFromRoute =
      location.search &&
      location.search.match(`\\?date=(\\d{4}-\\d{2}-\\d{2})`)[1];
    if (dataFromRoute) {
      const dateMoment = moment(dataFromRoute).utc();
      const dateFrom = dateMoment.toISOString();
      const dateTo = dateMoment.add(1, 'days').toISOString();
      setDefaultFilters({ dateTime_From: dateFrom, dateTime_To: dateTo });
    }
  }, [location.search]);

  const onMessageHandler = useCallback(
    ({ detail: message }) => {
      const messageData = getPredefinedEvent(message);
      if (messageData && messageData.mailBoxId === mailBoxId) {
        getLetterById(messageData.letterId)
          .then(data => {
            if (data.status === ignoredStatus) {
              dispatch(actions.removeItemFromList(data));
            } else {
              dispatch(actions.updateItemInList(data));
            }
          })
          .catch(() =>
            enqueueSnackbar('Unable to update list. Press refresh', {
              variant: 'error',
            }),
          );
      }
    },
    [dispatch, mailBoxId],
  );

  useEffect(() => {
    eventBus.on(events.SocketMessageReceived, onMessageHandler);

    return () =>
      eventBus.remove(events.SocketMessageReceived, onMessageHandler);
  }, [onMessageHandler]);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);

  const getText = value =>
    translate('resources.mailBox.documents.'.concat(value));

  const onRefreshClick = async value => {
    const result = await reParseMessage(value.id);
    if (result) {
      enqueueSnackbar('Message re-parse queued', { variant: 'info' });
    } else {
      enqueueSnackbar('Message re-parse failed', { variant: 'error' });
    }
  };

  const onDeleteClick = value => {
    setDeleteId(value.id);
    setDeleteDialogOpen(true);
  };
  const onAddClick = value => {
    redirect(`/mailbox/${mailBoxId}/letter/${value.messageId}/createParser`);
  };

  const onEditClick = value => {
    redirect(
      `/mailbox/${mailBoxId}/letter/${value.messageId}/parser/${value.parserId}/updateParser`,
    );
  };

  const onDeleteLetterHandler = async () => {
    const result = await removeLetter(deleteId);
    if (!result) {
      enqueueSnackbar('Unable to remove record', { variant: 'error' });
    } else {
      dispatch(actions.removeItemFromList({ id: deleteId }));
    }
    setDeleteDialogOpen(null);
    setDeleteId(null);
  };

  const onCancelDeleteHandler = () => {
    setDeleteDialogOpen(null);
    setDeleteId(null);
  };

  const onLetterClick = value => {
    redirect(`/mailbox/${mailBoxId}/letter/${value.messageId}`);
  };

  const [testDialogOpen, setTestDialogOpen] = useState(false);
  const [testDialogData, setTestDialogData] = useState(null);
  const [testDialogLoading, setTestDialogLoading] = useState(false);
  const [triggersForMessage, setTriggersForMessage] = useState(null);

  const onTestClick = messageId => {
    setTestDialogLoading(true);
    setTestDialogOpen(true);
    testMessage(messageId, +mailBoxId)
      .then(data => {
        setTestDialogData(data);
      })
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
      .finally(() => setTestDialogLoading(false));
  };

  const onTestDialogClose = () => {
    setTestDialogData(null);
    setTestDialogOpen(false);
  };

  const displayTriggers = (id, status, row) => {
    if (status) {
      setTriggersForMessage(row.messageId);
    }
  };

  return (
    <div className={classes.tableWrapper} key={version}>
      <WarningMessage mailboxId={mailBoxId} setWarningIsOpen={() => {}} />
      <div className={classes.tableContainer}>
        <Table
          resource={resource}
          actionsComponent={
            <ListActions
              onRefresh={refresh}
              globalExportType="Emails"
              filterIdParams={{ mailBoxId }}
              onUploadOpen={() => setUploadDialogOpen(true)}
            />
          }
          bulkActions={<BulkActionButtons mailBoxId={mailBoxId} />}
          defaultSort={{
            fieldName: 'dateTime',
            order: sortOrders.desc,
          }}
          defaultFilters={defaultFilters}
          columns={[
            {
              name: 'Sender',
              key: 'mailFrom',
              minWidth: 270,
              sortable: true,
              searchable: true,
            },
            {
              name: 'Subject',
              key: 'subject',
              fontSize: 'standard',
              sortable: true,
              searchable: true,
              isCustomAccessor: true,
              accessor: rowData => (
                <SubjectField
                  record={rowData}
                  source="subject"
                  onClick={onLetterClick}
                />
              ),
              minWidth: 350,
            },
            {
              name: 'Status',
              sortable: true,
              searchable: true,
              key: 'status',
              dropDownValues: letterStatusesOptions,
              type: TableTypes.dropdown,
              filterByAutocomplete: 'status',
              fontSize: 'standard',
              isCustomAccessor: true,
              accessor: rowData => (
                <StatusField record={rowData} source="status" />
              ),
              minWidth: 220,
              width: 220,
            },
            {
              name: 'Trigger',
              key: 'isTriggered',
              width: 100,
              minWidth: 100,
              isCustomAccessor: true,
              accessor: row => (
                <div className={classes.center}>
                  <StatusCircle
                    handleClick={displayTriggers}
                    row={row}
                    rowKey="isTriggered"
                  />
                </div>
              ),
            },
            {
              name: 'Date Received',
              key: 'dateTime',
              sortable: true,
              isCustomAccessor: true,
              accessor: rowData => (
                <DateTimeField record={rowData} source="dateTime" />
              ),
              minWidth: 175,
            },
            {
              name: 'Parser',
              sortable: true,
              searchable: true,
              key: 'parserName',
              fontSize: 'standard',
              minWidth: 250,
            },
            {
              name: 'Parse Date',
              key: 'dateParsed',
              sortable: true,
              isCustomAccessor: true,
              accessor: rowData => (
                <DateTimeField record={rowData} source="dateParsed" />
              ),
              minWidth: 175,
            },
            {
              name: 'Actions',
              key: TableTypes.actions,
              type: TableTypes.actions,
              accessor: rowData => (
                <ActionField
                  label={getText('actions')}
                  record={rowData}
                  handleUpdateClick={onRefreshClick}
                  handleDeleteClick={onDeleteClick}
                  handleAddClick={onAddClick}
                  handleUpdateParserClick={onEditClick}
                  handleTestClick={onTestClick}
                  mailBoxId={mailBoxId}
                />
              ),
              minWidth: 175,
              width: 175,
            },
          ]}
        />
      </div>
      <ConfirmDialog
        open={!!deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        onAccept={onDeleteLetterHandler}
        onCancel={onCancelDeleteHandler}
        title={getText('deleteLetterCaption')}
        content={getText('deleteLetterText')}
      />
      <UploadDialog open={uploadDialogOpen} setOpen={setUploadDialogOpen} />
      <TestBox
        open={testDialogOpen}
        onClose={onTestDialogClose}
        loading={testDialogLoading}
        data={testDialogData}
      />
      <TriggersList
        isOpen={!!triggersForMessage}
        onClose={() => setTriggersForMessage(null)}
        letterId={triggersForMessage}
      />
    </div>
  );
};

LettersList.propTypes = {
  resource: PropTypes.string,
  mailBoxId: PropTypes.string,
};

export default LettersList;
