import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ButtonGroupMui from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MenuItem from '@material-ui/core/MenuItem';
import { Popover } from '@material-ui/core';

import outputDataFormats from '@constants/outputDataFormats';

import ExportButton from '@common/buttons/ExportButton/ExportButton';

import useStyles from './styles';

const ExportButtonGroup = ({ disabled, globalExportType, filters }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedFileType, setSelectedFileType] = useState('Csv');

  const open = Boolean(anchorEl);

  const handleMenuItemClick = (event, index) => {
    setSelectedFileType(index);
    setAnchorEl(null);
  };

  const handleToggle = event => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <ButtonGroupMui
      variant="contained"
      disabled={disabled}
      className={classes.buttonGroup}
    >
      <ExportButton
        disabled={disabled}
        globalExportType={globalExportType}
        globalExportFileType={outputDataFormats[selectedFileType]}
        filters={filters}
      />
      <Button onClick={handleToggle} className={classes.iconButton}>
        <ArrowDropDownIcon />
      </Button>
      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {Object.keys(outputDataFormats).map(format => (
          <MenuItem
            key={format}
            selected={format === selectedFileType}
            onClick={event => handleMenuItemClick(event, format)}
          >
            {`Export as ${format.toUpperCase()}`}
          </MenuItem>
        ))}
      </Popover>
    </ButtonGroupMui>
  );
};

ExportButtonGroup.propTypes = {
  disabled: PropTypes.bool,
  globalExportType: PropTypes.string,
  filters: PropTypes.objectOf(PropTypes.string),
};

export default ExportButtonGroup;
