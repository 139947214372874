import { makeStyles } from '@material-ui/core/styles/index';

const useStyles = makeStyles(() => ({
  container: {
    margin: '0 16px 0 11px',
    boxSizing: 'border-box',
    '& tbody': {
      maxHeight: '70vh',
    },
  },
  inputContainer: {
    display: 'flex',
    gap: '10px',
    marginBottom: '10px',
    alignItems: 'flex-end',
  },
  input: {
    width: '30%',
  },
  successText: {
    textAlign: 'center',
    color: '#67AD5B',
    borderBottom: '1px solid #000',
    paddingBottom: '8px',
    marginBottom: '8px',
  },
  statisticContainer: {
    width: '500px',
    maxHeight: '90vh',
    overflowY: 'scroll',
  },
  statisticList: {
    borderBottom: '1px solid #000',
    paddingBottom: '8px',
    marginBottom: '8px',
    listStyleType: 'none',
    padding: 0,
    '& li': {
      display: 'flex',
      '& strong': {
        width: '50%',
      },
    },
  },
  failedList: {
    listStyleType: 'none',
    padding: 0,
  },
}));

export default useStyles;
