import React from 'react';
import PropTypes from 'prop-types';

import { getAutotaskTableColumns } from '@components/PsaContacts/constants';
import Table from '@components/Table';
import PsaContactsTableBulkActions from '@components/PsaContacts/components/PsaContactsTableBulkActions';
import Actions from '@components/PsaContacts/components/actions/Actions';

const AutotaskContacts = ({
  psaId,
  resource,
  psaName,
  syncDataIntoDatabase,
}) => {
  return (
    <Table
      resource={resource}
      actionsComponent={
        <Actions syncDataIntoDatabase={syncDataIntoDatabase} crmId={psaId} />
      }
      columns={getAutotaskTableColumns(psaId, syncDataIntoDatabase)}
      bulkActions={
        <PsaContactsTableBulkActions psaId={psaId} psaName={psaName} />
      }
    />
  );
};

AutotaskContacts.propTypes = {
  psaId: PropTypes.number.isRequired,
  resource: PropTypes.string.isRequired,
  psaName: PropTypes.string.isRequired,
  syncDataIntoDatabase: PropTypes.bool.isRequired,
};

export default AutotaskContacts;
