import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  iconButton: {
    backgroundColor: '#3a3e45',
    '& svg': {
      fill: 'white',
    },
    '&:hover': {
      backgroundColor: '#000',
    },
  },
  buttonGroup: {
    position: 'relative',
  },
}));

export default useStyles;
