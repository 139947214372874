import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';

import ThunkGetCountries from '@store/slices/countries/thunks';
import { ThunkGetTenantCountry } from '@store/slices/tenant/thunks';
import {
  ThunkCreateNewHelpdeskConfiguration,
  ThunkGetAiAgentTypes,
  ThunkGetHelpdeskLanguages,
} from '@store/slices/helpdeskAI/thunks';
import { ThunkGetCrmDefaultsExtended } from '@store/slices/crm/thunks';
import { ThunkGetHelpdeskIncomingPhoneNumbers } from '@store/slices/helpdeskAI/thunks/getIncomingPhoneNumbers';
import { actions } from '@store/actions';
import { countryCodesListSelector } from '@store/slices/countries';
import { currentUserDataSelector } from '@store/selectors';

import {
  aiMessageVariables,
  HELP_ICON_TOOLTIP,
  helpdeskGenders,
  helpdeskLanguages,
  KB_AI_ASSIST,
  notesTypes,
  voiceAssistDefaultMessages,
} from '@ui/pages/portalSettings/HelpdeskAI/constants';
import { helpdeskAIValidation } from '@ui/pages/portalSettings/HelpdeskAI/helpers';

import iconPlus from '@assets/icons/plusGrey.svg';

import Input from '@components/Auth/Common/Input';
import FieldWrapper from '@common/form/FieldWrapper';
import ReusableButton from '@common/Button/Button';
import DropDown from '@components/Auth/Common/DropDown';
import Loading from '@common/Loading/Loading';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import TemplateMenu from '@components/Ticketing/Notifications/components/TemplateMenu';
import Helper from '@ui/common/Helper';
import AutocompleteFormInput from '@common/AutocompleteFormInput/AutocompleteFormInput';
import HelpdeskIntegrationPhoneField from '@ui/pages/portalSettings/HelpdeskAI/components/HelpdeskIntegrationPhoneField';

import addFlagsToOptions from '@constants/addFlagsToOptions';
import useSharedStyles from '@components/CrmTicket/sharedStyles';
import nationalFormatPhoneNumber from '@utils/nationalFormatPhoneNumber';

import useStyles from '../styles';

const CreateHelpdeskIntegration = ({ open = true, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [crmOptions, setCrmOptions] = useState([]);
  const [countryCode, setCountryCode] = useState('US');
  const [processing, setProcessing] = useState(false);
  const [incomingPhoneNumbers, setIncomingPhoneNumbers] = useState([]);
  const [initialValues, setInitialValues] = useState({
    language: helpdeskLanguages[0]?.value,
    isCallRecordingEnabled: true,
    incomingPhoneNumber: '',
    callTransferPhoneNumber: '',
    addCallRecordingAsInternalNote: 1,
    voiceType: 1,
    introMessage: voiceAssistDefaultMessages.intro,
    outroMessage: voiceAssistDefaultMessages.outro,
    isActive: 'true',
  });
  const [languages, setLanguages] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [inputName, setInputName] = useState('');
  const [agentTypes, setAgentTypes] = useState([]);

  const sharedStyles = useSharedStyles();
  const classes = useStyles({ loading });
  const dispatch = useDispatch();
  const { countries } = useSelector(countryCodesListSelector);
  const tenantCountryCode = useSelector(currentUserDataSelector)
    ?.tenantCountryCode;

  const countryCodesOptions = useMemo(() => addFlagsToOptions(countries), [
    countries,
  ]);

  const initialIcon = useMemo(
    () => (
      <img
        src={`https://flagcdn.com/w20/${countryCode.toLowerCase()}.png`}
        alt={countryCode}
      />
    ),
    [countryCode],
  );

  const openVariablesClick = (target, name) => {
    setAnchorEl(target);
    setInputName(name);
  };

  const submit = values => {
    const payload = {
      ...values,
      name: values.agentName,
      isActive: values.isActive === 'true',
      callTransferPhoneNumber: '',
      incomingPhoneNumber:
        values.incomingPhoneNumberCountryCode.split(' ')[1] +
        values.incomingPhoneNumber.replace(/[^0-9]/g, ''),
      isCallRecordingEnabled: values.addCallRecordingAsInternalNote !== 0,
      addCallRecordingAsInternalNote:
        values.addCallRecordingAsInternalNote !== 0 &&
        values.addCallRecordingAsInternalNote === 1,
    };

    if (values.isActive === 'true') {
      payload.callTransferPhoneNumber =
        values.callTransferPhoneNumberCountryCode.split(' ')[1] +
        values.callTransferPhoneNumber.replace(/[^0-9]/g, '');
    }
    setProcessing(true);
    dispatch(ThunkCreateNewHelpdeskConfiguration(payload))
      .unwrap()
      .then(res => {
        dispatch(actions.updateItemInList(res));
        onClose();
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const onMount = useCallback(async () => {
    setLoading(true);
    Promise.all([
      dispatch(ThunkGetCrmDefaultsExtended()).unwrap(),
      dispatch(ThunkGetCountries()).unwrap(),
      dispatch(ThunkGetTenantCountry()).unwrap(),
      dispatch(ThunkGetHelpdeskLanguages()).unwrap(),
      dispatch(ThunkGetHelpdeskIncomingPhoneNumbers()).unwrap(),
      dispatch(ThunkGetAiAgentTypes()).unwrap(),
    ])
      .then(
        ([
          defaultOptions,
          countriesOptions,
          tenantCountry,
          languagesOptions,
          incomingPhoneNumbersOptions,
          agentTypesAutocomplete,
        ]) => {
          setCrmOptions(defaultOptions);
          setCountryCode(tenantCountry.data);
          setLanguages(languagesOptions);
          setAgentTypes(agentTypesAutocomplete);

          const tenantRegion = countriesOptions.find(
            item => item.code === tenantCountry.data,
          );
          setIncomingPhoneNumbers(
            addFlagsToOptions(
              incomingPhoneNumbersOptions,
              option => option.id,
              option =>
                nationalFormatPhoneNumber(
                  option.phoneNumber,
                  tenantCountryCode,
                ),
              'countryCode',
            ),
          );
          setInitialValues(state => ({
            crmTicketDefaultId: defaultOptions[0]?.defaultId,
            incomingPhoneNumberCountryCode: tenantRegion
              ? `${tenantRegion?.code} ${tenantRegion?.dialCode}`
              : 'US +1',
            callTransferPhoneNumberCountryCode: tenantRegion
              ? `${tenantRegion?.code} ${tenantRegion?.dialCode}`
              : 'US +1',
            ...state,
          }));
        },
      )
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, tenantCountryCode]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle className={classes.dialogHeader}>
        Add New AI VoiceAssist Configuration
        <Helper link={KB_AI_ASSIST} tooltip={HELP_ICON_TOOLTIP} />
      </DialogTitle>
      <DialogContent className={classes.dialog}>
        <Form
          onSubmit={submit}
          initialValues={initialValues}
          validate={helpdeskAIValidation}
          render={({ handleSubmit, form, values }) =>
            loading ? (
              <Loading />
            ) : (
              <form onSubmit={handleSubmit} className={classes.form}>
                <FieldWrapper
                  label="Agent Name"
                  labelSize={12}
                  contentSize={12}
                  isRequired
                  content={
                    <Field
                      id="aiAssist-add-agent-name"
                      name="agentName"
                      component={Input}
                      disabled={processing}
                    />
                  }
                />

                <FieldWrapper
                  label="Agent Type"
                  labelSize={12}
                  contentSize={12}
                  isRequired
                  content={
                    <Field
                      id="aiAssist-add-typeId"
                      name="typeId"
                      component={DropDown}
                      options={agentTypes}
                      disabled={processing}
                    />
                  }
                />

                <FieldWrapper
                  label="Company Name"
                  labelSize={12}
                  contentSize={12}
                  isRequired
                  content={
                    <Field
                      id="aiAssist-add-company-name"
                      name="companyName"
                      component={Input}
                      disabled={processing}
                    />
                  }
                />

                <div className={classes.column}>
                  <FieldWrapper
                    label="Gender"
                    labelSize={4}
                    contentSize={8}
                    isRequired
                    alignItems="center"
                    content={
                      <Field
                        id="aiAssist-add-gender"
                        name="voiceType"
                        component={DropDown}
                        options={helpdeskGenders}
                        disabled={processing}
                      />
                    }
                  />

                  <FieldWrapper
                    label="Language"
                    labelSize={4}
                    contentSize={8}
                    isRequired
                    alignItems="center"
                    content={
                      <Field
                        id="aiAssist-add-language"
                        name="language"
                        component={DropDown}
                        options={languages}
                        disabled={processing}
                        required
                      />
                    }
                  />
                </div>

                <FieldWrapper
                  label="Greeting Message"
                  labelSize={12}
                  contentSize={12}
                  isRequired
                  content={
                    <div className={classes.templateInput}>
                      <Field
                        id="aiAssist-add-greeting-message"
                        name="introMessage"
                        component={Input}
                        disabled={processing}
                        multiline
                        minRows={1}
                        maxRows={5}
                      />
                      <ActionButton
                        handler={event =>
                          openVariablesClick(
                            event.currentTarget,
                            'introMessage',
                          )
                        }
                        icon={<img src={iconPlus} alt="iconPlus" />}
                        toolTip="Add template variable"
                        disabled={processing}
                        classNameWrapper={classes.icon}
                      />
                    </div>
                  }
                />
                <FieldWrapper
                  label="Closing Message"
                  labelSize={12}
                  contentSize={12}
                  isRequired
                  content={
                    <div className={classes.templateInput}>
                      <Field
                        id="aiAssist-add-closing-message"
                        name="outroMessage"
                        component={Input}
                        disabled={processing}
                        multiline
                        minRows={1}
                        maxRows={5}
                      />
                      <ActionButton
                        handler={event =>
                          openVariablesClick(
                            event.currentTarget,
                            'outroMessage',
                          )
                        }
                        icon={<img src={iconPlus} alt="iconPlus" />}
                        toolTip="Add template variable"
                        disabled={processing}
                        classNameWrapper={classes.icon}
                      />
                    </div>
                  }
                />

                <FieldWrapper
                  label="Incoming Phone Number"
                  labelSize={12}
                  contentSize={12}
                  showLabel
                  isRequired
                  classNameLabelInner={classes.alignLeft}
                  content={
                    <Field
                      name="incomingPhoneNumberId"
                      id="aiAssist-add-incoming-phone-number"
                      render={AutocompleteFormInput}
                      items={incomingPhoneNumbers}
                      isIconShow
                      classNameWrapper={classes.incomingPhoneNumber}
                      size="small"
                    />
                  }
                />
                <HelpdeskIntegrationPhoneField
                  countryCodesOptions={countryCodesOptions}
                  initialIcon={initialIcon}
                  disabled={processing || values.isActive === 'false'}
                />
                <FieldWrapper
                  label="Ticket Properties"
                  labelSize={12}
                  contentSize={12}
                  isRequired
                  content={
                    <Field
                      id="aiAssist-add-psa-ticket-default"
                      name="crmTicketDefaultId"
                      component={DropDown}
                      options={crmOptions}
                      disabled={processing}
                      labelName="defaultName"
                      valueName="defaultId"
                    />
                  }
                />
                <FieldWrapper
                  label="Save recording as"
                  labelSize={6}
                  contentSize={6}
                  alignItems="center"
                  content={
                    <Field
                      name="addCallRecordingAsInternalNote"
                      id="aiAssist-add-call-recording-as-internal-note"
                      disabled={processing}
                      options={notesTypes}
                      component={DropDown}
                    />
                  }
                />
                <div className={sharedStyles.dialogActionsContainer}>
                  <ReusableButton
                    id="aiAssist-add-close"
                    label="Close"
                    onClick={onClose}
                    disabled={processing}
                  />
                  <ReusableButton
                    id="aiAssist-add-create"
                    label="Create"
                    type="submit"
                    viewType="blue"
                    loading={processing}
                    disabled={processing}
                  />
                </div>
                <TemplateMenu
                  anchorEl={anchorEl}
                  setAnchorEl={setAnchorEl}
                  form={form}
                  name={inputName}
                  templateVariables={aiMessageVariables}
                />
              </form>
            )
          }
        />
      </DialogContent>
    </Dialog>
  );
};

CreateHelpdeskIntegration.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default CreateHelpdeskIntegration;
