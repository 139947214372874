import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { PSA_CONTACTS } from '@services/requests/requestResources';
import { ThunkGetKaseyaIntegrationCompanies } from '@store/slices/integration/thunks';

import { getKaseyaTableColumns } from '@components/PsaContacts/constants';

import Actions from '@components/PsaContacts/components/actions/Actions';
import Table from '@components/Table';
import PsaContactsTableBulkActions from '@components/PsaContacts/components/PsaContactsTableBulkActions';

import crmSources from '@constants/crmSources';

const KaseyaContacts = ({ psaId, syncDataIntoDatabase }) => {
  const [companies, setCompanies] = useState([]);

  const dispatch = useDispatch();

  const resource = useMemo(() => `${PSA_CONTACTS}/kaseya/${psaId}`, [psaId]);

  const onMount = useCallback(() => {
    dispatch(ThunkGetKaseyaIntegrationCompanies({ crmId: psaId }))
      .unwrap()
      .then(res => {
        setCompanies(res);
      });
  }, [dispatch, psaId]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <Table
      resource={resource}
      actionsComponent={
        <Actions syncDataIntoDatabase={syncDataIntoDatabase} crmId={psaId} />
      }
      columns={getKaseyaTableColumns(psaId, companies)}
      bulkActions={
        <PsaContactsTableBulkActions
          psaId={psaId}
          psaName={crmSources.ConnectWise.name}
        />
      }
    />
  );
};

KaseyaContacts.propTypes = {
  psaId: PropTypes.number.isRequired,
  syncDataIntoDatabase: PropTypes.bool.isRequired,
};

export default KaseyaContacts;
