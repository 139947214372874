import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    margin: '8px 16px 0 11px',
    '& tbody': {
      maxHeight: '72vh',
    },
  },
  actionsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '60px',
  },
  plusIcon: {
    marginRight: '0.5rem',
  },
  createButton: {
    marginTop: '6px',
  },
  idpWrapper: {
    width: '90%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
  },
  actionButton: {
    minWidth: 'auto',
  },
  duoIcon: {
    '& img': {
      width: '25px',
      height: '25px',
    },
  },
  editButton: {
    padding: '0.3125rem',
    transition: '0.3s',
    minWidth: 0,
    color: 'transparent',

    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.2)',
      backgroundColor: 'transparent',
    },
  },
}));

export default useStyles;
