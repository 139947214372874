import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { ThunkGetIntegrationCompanies } from '@store/slices/integration/thunks';

import { getSuperOpsTableColumns } from '@components/PsaContacts/constants';

import Actions from '@components/PsaContacts/components/actions/Actions';
import Table from '@components/Table';
import PsaContactsTableBulkActions from '@components/PsaContacts/components/PsaContactsTableBulkActions';

const SuperOpsContacts = ({
  psaId,
  resource,
  psaName,
  syncDataIntoDatabase,
}) => {
  const [companies, setCompanies] = useState([]);

  const dispatch = useDispatch();

  const onMount = useCallback(() => {
    dispatch(ThunkGetIntegrationCompanies({ id: psaId }))
      .unwrap()
      .then(res => {
        setCompanies(res);
      });
  }, [dispatch, psaId]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <Table
      actionsComponent={
        <Actions syncDataIntoDatabase={syncDataIntoDatabase} crmId={psaId} />
      }
      resource={resource}
      columns={getSuperOpsTableColumns(psaId, companies)}
      bulkActions={
        <PsaContactsTableBulkActions psaId={psaId} psaName={psaName} />
      }
    />
  );
};

SuperOpsContacts.propTypes = {
  psaId: PropTypes.number.isRequired,
  resource: PropTypes.string.isRequired,
  psaName: PropTypes.string.isRequired,
  syncDataIntoDatabase: PropTypes.bool.isRequired,
};

export default SuperOpsContacts;
