import React, { memo } from 'react';
import PropTypes from 'prop-types';

import ListActions from '@common/ListActions/ListActions';

import { getDataFromToken } from '@utils/getDataFromToken';
import roles from '@constants/roles';

const Actions = ({ crmId, syncDataIntoDatabase }) => {
  const { roleId } = getDataFromToken();
  const isAdminRole =
    roleId === roles.SUPER_ADMIN || roleId === roles.TENANT_ADMIN;

  if (!isAdminRole || !syncDataIntoDatabase) {
    return null;
  }

  return (
    <ListActions globalExportType="CrmContacts" filterIdParams={{ crmId }} />
  );
};

Actions.propTypes = {
  crmId: PropTypes.number.isRequired,
  syncDataIntoDatabase: PropTypes.bool,
};

export default memo(Actions);
