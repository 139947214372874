import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { EditButton, useVersion } from 'react-admin';
import { Add } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

import roles from '@constants/roles';

import { getDataFromToken } from '@utils/getDataFromToken';
import { CREATE_USER, USERS } from '@constants/routes';
import { TableTypes } from '@components/Table/constants';

import Table from '@components/Table';
import BulkActionButtons from '@common/BulkActionButtons';
import DateTimeField from '@common/TableComponents/DateTimeField';
import ListActions from '@common/ListActions/ListActions';

import ReusableButton from '@common/Button/Button';
import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';
import googleIcon from '@assets/logos/google-mobile.svg';
import microsoftIcon from '@assets/logos/microsoft-mobile.svg';
import iconEdit from '@assets/icons/edit.svg';
import Switch2mfaButton from './components/Switch2mfaButton';
import DeleteActionField from './components/DeleteActionField';
import LoginAsButton from './components/LoginAsButton';

import useStyles from './styles';

const UsersList = () => {
  const { push } = useHistory();
  const classes = useStyles();
  const version = useVersion();
  const { userId } = getDataFromToken();

  const userRole = useMemo(() => {
    const { roleId } = getDataFromToken();

    return {
      isSuperAdmin: roleId === roles.SUPER_ADMIN,
      isAdmin: roleId === roles.SUPER_ADMIN || roleId === roles.TENANT_ADMIN,
    };
  }, []);

  const columns = useMemo(
    () =>
      [
        {
          name: 'Name',
          key: 'name',
          sortable: true,
          searchable: true,
          minWidth: 200,
        },
        {
          name: 'Email',
          key: 'email',
          sortable: true,
          searchable: true,
          minWidth: 250,
        },
        {
          name: 'Role',
          key: 'role',
          width: 150,
          minWidth: 150,
        },
        userRole.isAdmin && {
          name: 'MFA',
          key: 'mfa',
          isCustomAccessor: true,
          accessor: rowData => <Switch2mfaButton record={rowData} />,
          width: 100,
          minWidth: 100,
        },
        userRole.isSuperAdmin && {
          name: 'Tenant',
          key: 'tenant',
          sortable: true,
          searchable: true,
          minWidth: 200,
        },
        userRole.isSuperAdmin && {
          name: 'Login',
          key: 'login',
          width: 100,
          minWidth: 100,
          isCustomAccessor: true,
          accessor: rowData => <LoginAsButton record={rowData} />,
        },
        {
          name: 'Last Login',
          key: 'lastLoginDateTime',
          minWidth: 175,
          isCustomAccessor: true,
          accessor: rowData => (
            <DateTimeField record={rowData} source="lastLoginDateTime" />
          ),
        },
        userRole.isAdmin && {
          name: 'External IDP',
          key: 'idp',
          minWidth: 140,
          isCustomAccessor: true,
          accessor: ({ microsoftLinked, googleLinked }) => (
            <div className={classes.idpWrapper}>
              <ActionFieldItem
                alt="Google"
                toolTip={`Google authentication is ${
                  googleLinked ? 'enabled' : 'disabled'
                }`}
                icon={googleIcon}
                disabled={!googleLinked}
              />
              <ActionFieldItem
                alt="Microsoft"
                toolTip={`Microsoft authentication is ${
                  microsoftLinked ? 'enabled' : 'disabled'
                }`}
                icon={microsoftIcon}
                disabled={!microsoftLinked}
              />
            </div>
          ),
        },
        userRole.isAdmin && {
          name: 'Actions',
          key: TableTypes.actions,
          type: TableTypes.actions,
          accessor: rowData => (
            <>
              <EditButton
                label=""
                icon={<img src={iconEdit} alt="iconEdit" />}
                record={rowData}
                basePath="users"
                className={classes.editButton}
              />
              <DeleteActionField record={rowData} />
            </>
          ),
          width: 100,
          minWidth: 100,
        },
      ].filter(p => p),
    [classes, userRole],
  );

  const actionComponents = useMemo(
    () => ({
      bulkActions: userRole.isAdmin ? (
        <BulkActionButtons excludeFromIdList={userId} url={`${USERS}/`} />
      ) : null,
      showCreateButton: userRole.isAdmin,
    }),
    [userRole, userId],
  );

  return (
    <div className={classes.container}>
      <Table
        key={version}
        defaultFilters={{ isActive: true }}
        defaultSort={{ fieldName: 'name', order: 'Asc' }}
        bulkActions={actionComponents.bulkActions}
        actionsComponent={
          <div className={classes.actionsWrapper}>
            {actionComponents.showCreateButton && (
              <ReusableButton
                size="md"
                classNameWrapper={classes.createButton}
                onClick={() => {
                  push(CREATE_USER);
                }}
              >
                <Add className={classes.plusIcon} />
                Create
              </ReusableButton>
            )}
            <ListActions globalExportType="Users" />
          </div>
        }
        columns={columns}
        resource="users"
      />
    </div>
  );
};

UsersList.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

export default UsersList;
