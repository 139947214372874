import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import cx from 'classnames';

import exportIcon from '@assets/icons/export.svg';
import { globalExportFile } from '@services/api';

import useStyles from './styles';

const ExportButton = ({
  disabled = false,
  globalExportType,
  globalExportFileType = 'csv',
  filters = {},
  className,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const onClick = async () => {
    setLoading(true);
    try {
      const file = await globalExportFile(
        globalExportType,
        globalExportFileType,
        filters,
      );
      const url = window.URL.createObjectURL(new Blob([file]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${globalExportType}.${globalExportFileType}`,
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }

    setLoading(false);
  };

  return (
    <Button
      onClick={onClick}
      disabled={loading || disabled}
      startIcon={
        loading ? (
          <CircularProgress className={classes.loader} />
        ) : (
          <img src={exportIcon} alt="export" />
        )
      }
      variant="contained"
      className={cx(classes.exportButton, className)}
    >
      {`Export as ${globalExportFileType.toUpperCase()}`}
    </Button>
  );
};

ExportButton.propTypes = {
  disabled: PropTypes.bool,
  globalExportType: PropTypes.string,
  globalExportFileType: PropTypes.string,
  filters: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.objectOf(PropTypes.string),
};

export default ExportButton;
