import React, { useCallback, useMemo, useState } from 'react';
import { useRefresh, useTranslate, useRedirect } from 'react-admin';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';

import { actions } from '@store/actions';

import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import DateTimeField from '@common/TableComponents/DateTimeField';
import Table from '@components/Table';
import ListActions from '@common/ListActions/ListActions';
import ActionField from './ActionField';
import SubjectField from '../Common/SubjectField';
import BulkActions from '../Emails/BulkActions/BulkActions';

import { removeLetter } from '../../helpers';

import useStyles from './styles';

const IgnoredList = ({ resource, mailBoxId }) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const classes = useStyles();
  const refresh = useRefresh();
  const { enqueueSnackbar } = useSnackbar();
  const translate = useTranslate();
  const redirect = useRedirect();
  const dispatch = useDispatch();

  const getText = useCallback(
    value => translate('resources.mailBox.documents.'.concat(value)),
    [translate],
  );

  const onRefreshClick = value => console.log(value);
  const onDeleteClick = value => {
    setDeleteId(value.id);
    setDeleteDialogOpen(true);
  };

  const onDeleteLetterHandler = async () => {
    const result = await removeLetter(deleteId);
    if (!result) {
      enqueueSnackbar(getText('unableRemoveLetter'), { variant: 'warning' });
    } else {
      dispatch(actions.removeItemFromList({ id: deleteId }));
      refresh();
    }
    setDeleteDialogOpen(null);
    setDeleteId(null);
  };

  const onCancelDeleteHandler = () => {
    setDeleteDialogOpen(null);
    setDeleteId(null);
  };

  const onLetterClick = useCallback(
    value => {
      const path = '/mailbox/'
        .concat(mailBoxId)
        .concat('/letter/')
        .concat(value.messageId);
      redirect(path);
    },
    [mailBoxId, redirect],
  );

  const columns = useMemo(
    () => [
      {
        name: 'Sender',
        key: 'mailFrom',
        sortable: true,
        searchable: true,
        minWidth: 200,
      },
      {
        name: 'Subject',
        key: 'subject',
        sortable: true,
        searchable: true,
        isCustomAccessor: true,
        accessor: rowData => (
          <SubjectField
            record={rowData}
            source="subject"
            onClick={onLetterClick}
          />
        ),
        minWidth: 200,
      },
      {
        name: 'Received date',
        key: 'dateTime',
        sortable: true,
        isCustomAccessor: true,
        accessor: rowData => (
          <DateTimeField record={rowData} source="dateTime" />
        ),
        width: 200,
        minWidth: 200,
      },
      {
        name: 'Actions',
        key: 'actions',
        type: 'actions',
        width: 100,
        minWidth: 100,
        accessor: rowData => (
          <ActionField
            record={rowData}
            handleUpdateClick={onRefreshClick}
            handleDeleteClick={onDeleteClick}
          />
        ),
      },
    ],
    [getText, onLetterClick],
  );

  return (
    <div className={classes.tableWrapper}>
      <Table
        bulkActions={
          <BulkActions mailBoxId={mailBoxId} displayIgnore={false} />
        }
        actionsComponent={
          <ListActions
            onRefresh={refresh}
            filterIdParams={{ mailBoxId }}
            globalExportType="IgnoredEmails"
          />
        }
        defaultSort={{ fieldName: 'dateTime', order: 'desc' }}
        columns={columns}
        resource={resource}
      />
      <ConfirmDialog
        open={!!deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        onAccept={onDeleteLetterHandler}
        onCancel={onCancelDeleteHandler}
        title={getText('deleteLetterCaption')}
        content={getText('deleteLetterText')}
      />
    </div>
  );
};

IgnoredList.propTypes = {
  resource: PropTypes.string,
  mailBoxId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default IgnoredList;
