import React from 'react';
import PropTypes from 'prop-types';

import Actions from '@components/PsaContacts/components/actions/Actions';
import Table from '@components/Table';
import PsaContactsTableBulkActions from '@components/PsaContacts/components/PsaContactsTableBulkActions';

import { getHaloTableColumns } from '@components/PsaContacts/constants';

const HaloContacts = ({ psaId, resource, psaName, syncDataIntoDatabase }) => {
  return (
    <Table
      resource={resource}
      actionsComponent={
        <Actions syncDataIntoDatabase={syncDataIntoDatabase} crmId={psaId} />
      }
      columns={getHaloTableColumns(psaId, syncDataIntoDatabase)}
      defaultSort={{ fieldName: 'name', order: 'DESC' }}
      bulkActions={
        <PsaContactsTableBulkActions psaId={psaId} psaName={psaName} />
      }
    />
  );
};

HaloContacts.propTypes = {
  psaId: PropTypes.number.isRequired,
  resource: PropTypes.string,
  psaName: PropTypes.string,
  syncDataIntoDatabase: PropTypes.bool,
};

export default HaloContacts;
