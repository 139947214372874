export const joinCodeWithPhone = (countryCode, number) => {
  let formattedNumber = number.toString();
  if (countryCode === 'GB +44' && formattedNumber[0] === '0') {
    formattedNumber = formattedNumber.slice(1);
  }
  return countryCode && number
    ? `${countryCode.replace(/[a-zA-Z\s]/g, '')}${formattedNumber}`
    : undefined;
};

export const styleOverflowType = { visible: 'visible', clip: 'clip' };
export const changeDocumentStyleOverflow = type => {
  document.documentElement.style.overflow = type;
  document.body.style.overflow = type;
};

export default {};
