import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { PSA_CONTACTS } from '@services/requests/requestResources';
import { ThunkGetCrmSources } from '@store/slices/treeView/thunks';
import crmSources from '@constants/crmSources';

import SyncroMspTicketTable from '@components/PsaTickets/components/SyncroMsp/SyncroMspTicketTable';
import ImportIntegrationContacts from '@components/PsaContacts/components/integrations/importIntegration/ImportIntegrationContacts';
import DropDown from '../Auth/Common/DropDown';
import Loading from '../common/Loading/Loading';
import ConnectwiseContacts from './components/integrations/connectwise/ConnectwiseContacts';
import KaseyaContacts from './components/integrations/kaseya/KaseyaContacts';

import { getTable } from './helpers';

import useStyles from './styles';
import { useCrmSyncSettings } from '../../../hooks/useCrmSyncSettings';

const allowedPsaTypes = [
  crmSources.ConnectWise.name,
  crmSources.Autotask.name,
  crmSources.Kaseya.name,
  crmSources.Halo.name,
  crmSources.Zendesk.name,
  crmSources.SuperOps.name,
  crmSources.SyncroMsp.name,
  crmSources.Microsoft.name,
  crmSources.ImportIntegration.name,
];

const PsaContacts = () => {
  const [selectedPsa, setSelectedPsa] = useState(undefined);
  const [resource, setResource] = useState(undefined);
  const [crmOptions, setCrmOptions] = useState([]);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const {
    loading: loadingCrmSettings,
    crmSettings: { syncDataIntoDatabase },
  } = useCrmSyncSettings(selectedPsa);
  const classes = useStyles({ syncDataIntoDatabase });

  const getPsaResource = value => {
    if (crmOptions?.length > 0) {
      const selectedOption = crmOptions.find(option => option.id === +value);
      switch (selectedOption?.crmType) {
        case crmSources.ConnectWise.name:
          setResource(`${PSA_CONTACTS}/connectwise/${selectedOption.id}`);
          return;
        case crmSources.Autotask.name:
          setResource(`${PSA_CONTACTS}/autotask/${selectedOption.id}`);
          return;
        case crmSources.Kaseya.name:
          setResource(`${PSA_CONTACTS}/kaseya/${selectedOption.id}`);
          return;
        case crmSources.Halo.name:
          setResource(`${PSA_CONTACTS}/halo/${selectedOption.id}`);
          return;
        case crmSources.Zendesk.name:
          setResource(`${PSA_CONTACTS}/zendesk/${selectedOption.id}`);
          return;
        case crmSources.SuperOps.name:
          setResource(`${PSA_CONTACTS}/superOps/${selectedOption.id}`);
          return;
        case crmSources.Microsoft.name:
          setResource(`${PSA_CONTACTS}/microsoft/${selectedOption.id}`);
          return;
        default:
          setResource(undefined);
      }
    }
  };

  const handleChange = useCallback(
    ({ target }) => {
      setSelectedPsa(target.value);
      getPsaResource(target.value);
    },
    [crmOptions],
  );

  const onMount = useCallback(async () => {
    setLoading(true);
    const options = await dispatch(ThunkGetCrmSources()).unwrap();
    setCrmOptions(options.filter(i => allowedPsaTypes.includes(i.crmType)));
    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  useEffect(() => {
    if (!!crmOptions && crmOptions.length === 1) {
      handleChange({ target: { value: crmOptions[0].id } });
    }
  }, [crmOptions, handleChange]);

  const table = useMemo(() => {
    if (selectedPsa && crmOptions?.length) {
      const selectedOption = crmOptions.find(
        option => option.id === +selectedPsa,
      );

      if (selectedOption?.crmType === crmSources.SyncroMsp.name) {
        return (
          <SyncroMspTicketTable
            crmId={selectedPsa}
            resource={`${PSA_CONTACTS}/syncro/${selectedPsa}`}
            defaultSortName="name"
            tableType="contacts"
            syncDataIntoDatabase={syncDataIntoDatabase}
          />
        );
      }

      if (selectedOption?.crmType === crmSources.ConnectWise.name) {
        return (
          <ConnectwiseContacts
            psaId={selectedPsa}
            syncDataIntoDatabase={syncDataIntoDatabase}
          />
        );
      }
      if (selectedOption?.crmType === crmSources.Kaseya.name) {
        return (
          <KaseyaContacts
            psaId={selectedPsa}
            syncDataIntoDatabase={syncDataIntoDatabase}
          />
        );
      }

      if (selectedOption?.crmType === crmSources.ImportIntegration.name) {
        return (
          <ImportIntegrationContacts
            psaId={selectedPsa}
            syncDataIntoDatabase={syncDataIntoDatabase}
          />
        );
      }

      return getTable(
        selectedOption?.crmType,
        selectedPsa,
        resource,
        syncDataIntoDatabase,
      );
    }
    return <></>;
  }, [selectedPsa, crmOptions, resource, syncDataIntoDatabase]);

  if (loading || loadingCrmSettings) return <Loading />;
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <DropDown
          options={crmOptions}
          input={{
            value: selectedPsa,
            onChange: handleChange,
          }}
          labelName="name"
          valueName="id"
          classNameWrapper={classes.inputContainer}
        />
      </div>
      {table}
    </div>
  );
};

export default PsaContacts;
