import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  exportButton: {
    backgroundColor: '#3a3e45',
    color: '#fff',
    textTransform: 'none !important',
    padding: '3.5px 20px',
    fontSize: '16px',
    minWidth: '110px',
    '&:hover': {
      backgroundColor: '#000',
    },
  },
  loader: {
    maxHeight: '20px',
    maxWidth: '20px',
    opacity: 0.5,
  },
}));

export default useStyles;
