import {
  phoneWithCharactersValidator,
  requiredValidator,
} from '@utils/validators';
import { validateRequired } from '@common/functions/validators';

export const helpdeskAIValidation = values => ({
  name: requiredValidator(values.agentName),
  agentName: requiredValidator(values.agentName),
  companyName: requiredValidator(values.companyName),
  incomingPhoneNumberId: validateRequired(values.incomingPhoneNumberId),
  crmTicketDefaultId: requiredValidator(values.crmTicketDefaultId),
  language: requiredValidator(values.language),
  callTransferPhoneNumberCountryCode:
    values.isActive === 'true'
      ? requiredValidator(values.callTransferPhoneNumberCountryCode)
      : undefined,
  callTransferPhoneNumber: phoneWithCharactersValidator(
    values.callTransferPhoneNumber,
    9,
    values.isActive === 'true',
  ),
  introMessage: requiredValidator(values.introMessage),
  outroMessage: requiredValidator(values.outroMessage),
  typeId: requiredValidator(values.typeId),
});

export default {};
