import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { PSA_CONTACTS } from '@services/requests/requestResources';

import { getConnectWiseTableColumns } from '@components/PsaContacts/constants';
import crmSources from '@constants/crmSources';

import Actions from '@components/PsaContacts/components/actions/Actions';
import Table from '@components/Table';
import PsaContactsTableBulkActions from '@components/PsaContacts/components/PsaContactsTableBulkActions';

const ConnectwiseContacts = ({ psaId, syncDataIntoDatabase }) => {
  const resource = useMemo(() => `${PSA_CONTACTS}/connectwise/${psaId}`, [
    psaId,
  ]);

  return (
    <Table
      resource={resource}
      actionsComponent={
        <Actions syncDataIntoDatabase={syncDataIntoDatabase} crmId={psaId} />
      }
      columns={getConnectWiseTableColumns(psaId, syncDataIntoDatabase)}
      bulkActions={
        <PsaContactsTableBulkActions
          psaId={psaId}
          psaName={crmSources.ConnectWise.name}
        />
      }
    />
  );
};

ConnectwiseContacts.propTypes = {
  psaId: PropTypes.number.isRequired,
  syncDataIntoDatabase: PropTypes.bool,
};

export default ConnectwiseContacts;
