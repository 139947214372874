import React, { useCallback, useEffect, useState } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import cx from 'classnames';

import { useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';

import NumberInput from '@components/Auth/Common/NumberInput';
import FieldWrapper from '@common/form/FieldWrapper';
import Input from '@components/Auth/Common/Input';
import DropDown from '@components/Auth/Common/DropDown';
import { getHintUsers } from '@components/Audit/helpers';
import FormSwitch from '@components/Auth/Common/FormSwitch';
import FormControlCheckBox from '@common/Checkbox/FormControlCheckBox';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import iconPlus from '@assets/icons/plusGrey.svg';
import TemplateMenu from '@components/Ticketing/Notifications/components/TemplateMenu';

import { ThunkGetTeamsConnections } from '@store/slices/teamsConnections/thunks';
import { ThunkGetCrmSources } from '@store/slices/treeView/thunks';
import { ThunkGetNewChatTeamsMessageVariables } from '@store/slices/clientPortalAdmin/thunks';

import useStyles from '../../styles';

const checkboxFields = [
  {
    label: 'Log each chat message individually',
    name: 'putEachMessageToTicketNote',
  },
  {
    label: 'Log Chat session to ticket on Close',
    name: 'logToTicketOnClose',
  },
  {
    label: 'Make ticket note internal',
    name: 'messageAsInternalNote',
  },
  {
    label: 'Allow Users to Re-Open Chat',
    name: 'allowToReOpen',
  },
  {
    label: 'Close ticket when chat closing',
    name: 'closeTicketWhenCloseChat',
  },
  {
    label: 'Change ticket status on message',
    name: 'changeTicketStatusOnMessage',
  },
];

const Chats = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [responseAnchorEl, setResponseAnchorEl] = useState(null);
  const [users, setUsers] = useState([]);

  const { values } = useFormState({ subscription: { values: true } });
  const form = useForm();

  const [teamsConnectionOptions, setTeamsConnectionOptions] = useState([]);
  const [
    teamsMessageTemplateVariables,
    setTeamsMessageTemplateVariables,
  ] = useState([]);

  const onMount = useCallback(async () => {
    await getHintUsers().then(res => {
      setUsers(res);
    });

    await dispatch(ThunkGetCrmSources());
    await dispatch(ThunkGetTeamsConnections())
      .unwrap()
      .then(d =>
        setTeamsConnectionOptions(
          d.map(i => ({
            label: i.name,
            value: i.id,
          })),
        ),
      );

    await dispatch(ThunkGetNewChatTeamsMessageVariables())
      .unwrap()
      .then(d => setTeamsMessageTemplateVariables(d));
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <Grid container>
      <Grid item xs={12} className={classes.switch}>
        <Field
          id="disableChatForPortal"
          name="disableChatForPortal"
          type="checkbox"
          component={FormSwitch}
          onChange={event => {
            const isActive = event.currentTarget.checked;
            form.change('disableChatForPortal', isActive);

            if (!isActive) {
              form.batch(() => {
                form.change('clientPortalAdmins', []);
                form.change('notifyAdmin', false);
                form.change('notifyAdminInMin', 0);
                form.change('logToEmailOnClose', false);
                form.change('emailForLogs', '');
                form.change('sendTeamsNotificationOnNewChat', false);
                form.change('teamsConnectionId', null);
                form.change('onNewChatTeamsNotificationTemplate', null);

                checkboxFields.forEach(field => {
                  form.change(field.name, false);
                });
              });
            }
          }}
          label="Display the Chat Feature in the Client Portal"
        />
      </Grid>
      <div
        className={cx(classes.mainWrapper, {
          [classes.disabled]: !values.disableChatForPortal,
        })}
      >
        <Grid item xs={12} className={classes.wrapper}>
          <FieldWrapper
            label="Chat Admins"
            labelSize={12}
            contentSize={12}
            spacing={0}
            fullWidth
            content={
              <Field
                name="clientPortalAdmins"
                id="clientPortalAdmins"
                render={DropDown}
                options={users}
                multiple
                labelName="name"
                valueName="id"
                disabled={!values.disableChatForPortal}
              />
            }
          />
        </Grid>
        <Grid item xs={12} className={classes.wrapper}>
          <Field
            label="Notify Chat Admin"
            name="notifyAdmin"
            id="notifyAdmin"
            render={FormControlCheckBox}
            type="checkbox"
            className={classes.checkBox}
            onChange={event => {
              const isActive = event.currentTarget.checked;
              form.change('notifyAdmin', isActive);

              if (!isActive) {
                form.change('notifyAdminInMin', 0);
              }
            }}
            disabled={!values.disableChatForPortal}
          />
        </Grid>
        <Grid item xs={12} className={classes.wrapper}>
          <FieldWrapper
            label="Notify Chat Admin in (mins)"
            labelSize={6}
            contentSize={6}
            fullWidth
            classNameContainer={classes.fieldWrapper}
            disabled={!values.notifyAdmin}
            content={
              <Field
                name="notifyAdminInMin"
                id="notifyAdminInMin"
                render={NumberInput}
                min={0}
                max={1000}
                disabled={!values.notifyAdmin}
              />
            }
          />
        </Grid>
        <Grid item xs={12} className={classes.wrapper}>
          <Field
            label="Log to email on closing"
            name="logToEmailOnClose"
            id="logToEmailOnClose"
            render={FormControlCheckBox}
            type="checkbox"
            className={classes.checkBox}
            onChange={event => {
              const isActive = event.currentTarget.checked;
              form.change('logToEmailOnClose', isActive);

              if (!isActive) {
                form.change('emailForLogs', '');
              }
            }}
            disabled={!values.disableChatForPortal}
          />
        </Grid>
        <Grid item xs={12} className={classes.wrapper}>
          <FieldWrapper
            label="Email for logs"
            labelSize={6}
            contentSize={6}
            fullWidth
            classNameContainer={classes.fieldWrapper}
            disabled={!values.logToEmailOnClose}
            content={
              <Field
                name="emailForLogs"
                id="emailForLogs"
                render={Input}
                dateProps={{ maxLength: 200 }}
                disabled={!values.logToEmailOnClose}
              />
            }
          />
        </Grid>
        {checkboxFields.map(field => (
          <Grid item xs={12} key={field.name} className={classes.wrapper}>
            <Field
              label={field.label}
              name={field.name}
              id={field.name}
              render={FormControlCheckBox}
              type="checkbox"
              className={classes.checkBox}
              disabled={!values.disableChatForPortal}
            />
          </Grid>
        ))}
        <Grid item xs={12} className={classes.wrapper}>
          <Field
            label="Send Teams notification on new chat"
            name="sendTeamsNotificationOnNewChat"
            id="sendTeamsNotificationOnNewChat"
            render={FormControlCheckBox}
            type="checkbox"
            className={classes.checkBox}
            onChange={event => {
              const isActive = event.currentTarget.checked;
              form.change('sendTeamsNotificationOnNewChat', isActive);

              if (!isActive) {
                form.batch(() => {
                  form.change('teamsConnectionId', null);
                  form.change('onNewChatTeamsNotificationTemplate', null);
                });
              }
            }}
            disabled={!values.disableChatForPortal}
          />
        </Grid>
        <Grid item xs={12} className={classes.wrapper}>
          <FieldWrapper
            label="Teams Connection"
            labelSize={6}
            contentSize={6}
            fullWidth
            classNameContainer={classes.fieldWrapper}
            disabled={!values.sendTeamsNotificationOnNewChat}
            content={
              <Field
                name="teamsConnectionId"
                id="teamsConnectionId"
                render={DropDown}
                options={teamsConnectionOptions}
                disabled={!values.sendTeamsNotificationOnNewChat}
              />
            }
          />

          <FieldWrapper
            label="Message template"
            labelSize={12}
            contentSize={12}
            fullWidth
            classNameContainer={classes.fieldWrapper}
            disabled={!values.sendTeamsNotificationOnNewChat}
            content={
              <div className={classes.dialogTemplate}>
                <ActionButton
                  handler={event => setResponseAnchorEl(event.currentTarget)}
                  icon={<img src={iconPlus} alt="iconPlus" />}
                  toolTip="Add template variable"
                  disabled={!values.sendTeamsNotificationOnNewChat}
                />
                <Field
                  name="onNewChatTeamsNotificationTemplate"
                  id="onNewChatTeamsNotificationTemplate"
                  render={Input}
                  multiline
                  minRows={6}
                  dateProps={{ maxLength: 300 }}
                  disabled={!values.sendTeamsNotificationOnNewChat}
                />
              </div>
            }
          />

          <TemplateMenu
            anchorEl={responseAnchorEl}
            setAnchorEl={setResponseAnchorEl}
            form={form}
            name="onNewChatTeamsNotificationTemplate"
            templateVariables={teamsMessageTemplateVariables}
          />
        </Grid>
      </div>
    </Grid>
  );
};

export default Chats;
